<!--
 * @Description: 调度任务详情
 * @Author: ChenXueLin
 * @Date: 2021-08-17 10:32:32
 * @LastEditTime: 2023-03-20 11:00:55
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'调度任务状态:' + basicInfo.schedulestatusName"
          >
            <template v-slot:rightButtons>
              <el-button
                type="primary"
                @click="lookReason"
                v-if="basicInfo.schedulestatus == 4"
                >查看异常完成原因
              </el-button>
              <el-button
                type="primary"
                @click="handleFinish"
                v-if="
                  basicInfo.schedulestatus == 1 || basicInfo.schedulestatus == 2
                "
                >异常完成</el-button
              >
            </template>
          </detail-title-content>

          <!-- 基本信息 -->
          <template>
            <div class="baseInfo-content-wrap">
              <!-- 基本信息 start -->
              <div class="baseInfo-box basicForm">
                <div class="edit-title">基本信息</div>
                <el-form label-width="120px" class="editForm" :inline="true">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="调度任务ID">
                        {{ basicInfo.scheduleId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="所属任务单">
                        {{ basicInfo.workNo }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="约定时间">
                        {{ basicInfo.taskArrangedate }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="所属片区">
                        {{ basicInfo.areaName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="片区工程组长">
                        {{ basicInfo.assignEngineerName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="作业地址">
                        {{ basicInfo.insAddress }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="创建人">
                        {{ basicInfo.createBy }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="16">
                      <el-form-item label="创建时间">
                        {{ basicInfo.createdTime }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="第一联系人">
                        {{ basicInfo.corpContactName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="第一联系电话">
                        {{ basicInfo.corpContactPhone }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="实施原因">
                        {{ basicInfo.implementationReason }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="第二联系人">
                        {{ basicInfo.contactName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="第二联系电话">
                        {{ basicInfo.contactPhone }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="调度备注">
                        {{ basicInfo.remark }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 基本信息 end -->
              <!-- 已指派调度信息 start -->
              <div class="task-list-content">
                <div class="edit-title">已指派调度信息</div>
                <el-table :data="tableData" height="400">
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in columnData"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    align="center"
                    label="操作"
                    width="120"
                  >
                    <template slot-scope="scope">
                      <e6-td-operate
                        :data="getOperateList(scope.row)"
                        @command="handleOperate($event, scope.row)"
                      ></e6-td-operate>
                    </template>
                  </el-table-column>
                </el-table>
                <section
                  class="pagination-wrapper fixed-section"
                  ref="paginationWrapper"
                >
                  <el-pagination
                    :page-size.sync="pageSize"
                    :current-page.sync="pageIndex"
                    :page-sizes="pageSizes"
                    :layout="layout"
                    :total="total"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  ></el-pagination>
                </section>
              </div>
              <!-- 已指派调度信息 end -->
            </div>
          </template>
        </div>
      </section>
      <!-- 表格 end -->
    </div>
    <!-- 查看异常提交原因 -->
    <abnormal-reason
      :reasonVisible="reasonVisible"
      :reasonLabel="reasonLabel"
      :title="dialogTitle"
      :reasonForm="reasonForm"
      @handleClose="handleClose"
    ></abnormal-reason>
    <!-- 异常提交输入 -->
    <abnormal-finish-dialog
      :abnormalFinishDialog="abnormalFinishDialog"
      :abnormalType="abnormalType"
      @refreshData="refreshPage"
      :scheduleId="realId"
      @handleClose="handleClose"
      v-if="abnormalFinishDialog"
    ></abnormal-finish-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import detailTitleContent from "@/components/detailTitleContent";
import { printError } from "@/utils/util";
import { getScheduleDetail, getScheduleInfoList, taskAbnormal } from "@/api";
import AbnormalFinishDialog from "@/components/workOrderManage/abnormalFinishDialog.vue";
import AbnormalReason from "@/components/workOrderManage/abnormalReason.vue";
export default {
  name: "schedulingTaskDetails",
  data() {
    return {
      basicInfo: {},
      /****分页*******/
      pageSize: 20,
      pageIndex: 1,
      total: 0,
      realId: "", //实际的调度任务id
      abnormalType: "1",
      columnData: [
        {
          fieldName: "scheduleInfoId",
          display: true,
          fieldLabel: "调度信息ID",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "scheduleInfoStatusName",
          display: true,
          fieldLabel: "调度信息状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerrpName",
          display: true,
          fieldLabel: "工程师",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerrpPhone",
          display: true,
          fieldLabel: "工程师电话",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskArrangedate",
          display: true,
          fieldLabel: "约定时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishTime",
          display: true,
          fieldLabel: "完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      /*****异常提交原因*******/
      reasonVisible: false, //异常提交原因
      reasonForm: {},
      dialogTitle: "查看异常原因",
      reasonLabel: "异常原因",
      /****异常提交处理********/
      abnormalFinishDialog: false
    };
  },
  mixins: [base, listPage],
  components: {
    detailTitleContent,
    AbnormalFinishDialog,
    AbnormalReason
  },
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  computed: {
    btnType() {
      let value = "";
      if (this.basicInfo.schedulestatus == 1) {
        value = "yellowBtn";
      }
      if (this.basicInfo.schedulestatus == 2) {
        value = "blueBtn";
      }
      if (
        this.basicInfo.schedulestatus == 3 ||
        this.basicInfo.schedulestatus == 4
      ) {
        value = "greenBtn";
      }
      return value;
    }
  },
  mounted() {},
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.scheduleId = hasParamsRoute.params.scheduleId;
      this.realId = this.scheduleId;
      if (this.scheduleId) {
        this.getDetail();
        this.queryList();
      }
    },
    //更新页面状态
    refreshPage() {
      this.queryList();
      this.getDetail();
    },
    //查询调度任务基本信息
    async getDetail() {
      try {
        this.loading = true;
        let res = await getScheduleDetail({ scheduleId: this.scheduleId });
        this.basicInfo = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查询已指派的调度信息列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getScheduleInfoList({
          scheduleTaskId: this.scheduleId,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 每页条数改变
    handleSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.queryList();
    },
    // 页码改变
    handleCurrentChange(curPage) {
      this.pageIndex = curPage;
      this.queryList();
      this.scrollTop = 0;
    },
    /********查看异常提交原因*********/
    //点击查看异常原因
    lookReason() {
      this.reasonVisible = true;
      this.getTaskAbnormal();
    },
    //调度任务---查看异常提交原因
    async getTaskAbnormal() {
      try {
        let res = await taskAbnormal({
          scheduleId: this.basicInfo.scheduleId
        });
        if (res.code == "OK") {
          this.reasonForm = res.data;
          this.reasonVisible = true;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    },
    // 点击异常提交
    handleFinish() {
      this.scheduleId = this.basicInfo.scheduleId;
      this.realId = this.scheduleId;
      this.abnormalType = 1;
      this.abnormalFinishDialog = true;
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      let status = [3, 4, 5];
      //["待接受","待作业","作业中",]
      if (
        status.findIndex(item => {
          return row.workOrderStatus == item;
        }) > -1
      ) {
        operates.push({
          label: "异常完成",
          id: 2
        });
      }
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        // this.routerPush({
        //   name: "taskListManage/ActionItemDetail",
        //   params: {
        //     refresh: true,
        //     itemId: row.workOrderId
        //   }
        // });
        this.routerPush({
          name: "taskListManage/schedulingInfoDetails",
          params: {
            refresh: true,
            scheduleInfoId: row.scheduleInfoId,
            taskId: row.taskId
          }
        });
      }
      if (val.id == 2) {
        //异常完成
        this.realId = row.workOrderId;
        this.abnormalType = 2;
        this.abnormalFinishDialog = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
